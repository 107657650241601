import React from 'react';
import { SignUpAppFooter } from '@truvity/js-core__uikit';
import { Link } from 'react-router-dom';

import { routes } from '../../../../routes';
import { COOKIE_DECLARATION_PAGE_TITLE } from '../../../pages/CookieDeclarationPage';
import { helmValues } from '../../../../utils/deploy-params';

export const AppFooter = () => (
    <SignUpAppFooter
        items={[
            {
                to: routes.COOKIE_DECLARATION.path,
                title: COOKIE_DECLARATION_PAGE_TITLE,
            },
        ]}
        Link={(props) => <Link {...props} />}
        statusPageUrl={helmValues.statusPageUrl}
    />
);
