import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';

type AppLayoutProps = {
    content: ReactNode;
    footer: ReactNode;
};

export const AppLayout = ({ content, footer }: AppLayoutProps) => (
    <Stack height="100%" justifyContent={'space-between'}>
        <Box flexGrow={1}>{content}</Box>
        {footer}
    </Stack>
);
