import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, GlobalStyles, StyledEngineProvider } from '@mui/material';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import '../../../fonts/fonts.css';
import { helmValues } from '../../../utils/deploy-params';

export const AppRuntime = ({ children }: React.PropsWithChildren<unknown>) => (
    <>
        <CssBaseline />
        <GlobalStyles
            styles={{
                html: { height: '100%' },
                body: { height: '100%' },
                '#root': { height: '100%' },
            }}
        />
        <StyledEngineProvider injectFirst>
            <GTMProvider
                state={
                    helmValues.tagManagerArgs
                        ? {
                              id: helmValues.tagManagerArgs.id,
                              environment: {
                                  gtm_auth: helmValues.tagManagerArgs.gtm_auth,
                                  gtm_preview: helmValues.tagManagerArgs.gtm_preview,
                              },
                          }
                        : undefined
                }
            >
                <BrowserRouter>{children}</BrowserRouter>
            </GTMProvider>
        </StyledEngineProvider>
    </>
);
