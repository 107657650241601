import React, { PropsWithChildren } from 'react';

import { SignupForm, SignupFormProps } from '../modules/SignupForm';
import { auth0ClientId, helmValues } from '../../utils/deploy-params';

export type SignupFormWithHelmParamsProps = Omit<
    SignupFormProps,
    'auth0ClientId' | 'auth0Domain' | 'auth0AllowedConnections' | 'redirectUrl'
>;

export const SignupFormWithHelmParams = ({ children, ...props }: PropsWithChildren<SignupFormWithHelmParamsProps>) => (
    <SignupForm
        {...props}
        auth0ClientId={auth0ClientId}
        auth0Domain={helmValues.auth0Domain}
        auth0AllowedConnections={helmValues.auth0AllowedConnections}
        redirectUrl={helmValues.auth0AuthRedirect}
    >
        {children}
    </SignupForm>
);
