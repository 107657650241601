import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { App } from './components/App';
import { helmValues, sentryDsn } from './utils/deploy-params';

if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        environment: helmValues.sentry?.environment,
        release: helmValues.sentry?.release,
        integrations: helmValues.sentry?.browserTracing ? [new BrowserTracing()] : undefined,
        tracePropagationTargets: helmValues.sentry?.tracePropagationTargets,
        // Performance Monitoring
        tracesSampleRate: helmValues.sentry?.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: helmValues.sentry?.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: helmValues.sentry?.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const rootEl = document.getElementById('root');

if (!rootEl) {
    throw new Error('Root element not found, failed to initialize the app');
}

const root = createRoot(rootEl);

root.render(<App />);
