import { styled } from '@mui/material';

export const SignUpTextContainerStyled = styled('div')(({ theme }) => ({
    paddingTop: '12px',
    paddingRight: '40px',
    [theme.breakpoints.down('md')]: {
        padding: '10px',
    },
    maxWidth: '800px',

    '&:empty': {
        display: 'none',
    },
}));

export const SignUpFormContainerStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    'span, p': {
        fontFamily: 'Object Sans',
    },
    '.auth0-lock.auth0-lock .auth0-lock-header-logo': {
        width: '100%',
        height: 70,
        marginBottom: 5,
    },
}));

// we need the below fix as at low width resolutions, the auth0 form would expand on all visible hight
export const SignUpFormStyled = styled('div')`
    .auth0-lock-cred-pane-internal-wrapper {
        height: auto !important;
    }
`;
