import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import HandshakeIcon from '@mui/icons-material/Handshake';

import { TextListWithIcons } from '../basic/TextListWithIcons';
import { ContentMainHeader } from '../basic/ContentMainHeader';
import { ContentParagraph } from '../basic/ContentParagraph';

import { SignupFormWithHelmParams } from './SignupFormWithHelmParams';

export const MainSignupForm = () => (
    <SignupFormWithHelmParams>
        <ContentMainHeader>Start building today</ContentMainHeader>
        <ContentParagraph>
            Get free early-access to the Truvity platform and play with the future of identity. Make instant trust a
            reality for your usecase and make document exchange simple.
        </ContentParagraph>
        <TextListWithIcons
            title="Get access to:"
            items={[
                {
                    icon: <ConstructionIcon />,
                    content: (
                        <>
                            The building blocks to implement a triangle of trust, and get access to the self-sovereign
                            identity technology stack
                        </>
                    ),
                },
                {
                    icon: <DescriptionIcon />,
                    content: (
                        <>
                            Start using the benefits of verifiable credentials, built-in verification mechanics and data
                            collection/resharing protocols
                        </>
                    ),
                },
                {
                    icon: <AirplanemodeActiveIcon />,
                    content: (
                        <>
                            Access our &lsquo;Travel application: Digital boarding pass and COVID test
                            credentials&rsquo; as a reference point, guiding you through the key concepts
                        </>
                    ),
                },
                {
                    icon: <HandshakeIcon />,
                    content: (
                        <>
                            Build with our extensive developer documentation and get direct support with your questions
                            from us and the community
                        </>
                    ),
                },
            ]}
        />
    </SignupFormWithHelmParams>
);
