import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

import { auth0LogoutUrl, helmValues } from '../../utils/deploy-params';

export const VerifyEmailPage = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') ?? undefined;

    return (
        <>
            <Helmet>
                <title>Email Verification</title>
            </Helmet>
            <Box textAlign="center" display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Typography variant="h1" gutterBottom>
                    Verification required
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you for signing up. Please check your inbox {email ? `(${email})` : ''} for a verification
                    email.
                </Typography>
                <Box mt={2}>
                    <Button variant="outlined" href={helmValues.auth0AuthRedirect}>
                        I have confirmed my email
                    </Button>
                </Box>
                <Box mt={1}>
                    <Button href={auth0LogoutUrl}>Change account</Button>
                </Box>
            </Box>
        </>
    );
};
