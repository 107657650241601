import React, { PropsWithChildren } from 'react';
import { Box, Link as MuiLink } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export type ActiveLinkProps = {
    to: string;
};

export const ActiveLink = ({ to, children }: PropsWithChildren<ActiveLinkProps>) => {
    const location = useLocation();

    return (
        <MuiLink component={location.pathname === to ? Box : Link} to={to} display="flex">
            {children}
        </MuiLink>
    );
};
