import { z } from 'zod';
import { getProjectValues, getSsiValue } from '@truvity/js-core__utils';

const helmValuesSchema = z.object({
    auth0Domain: z.string(),
    auth0AuthRedirect: z.string(),
    auth0AllowedConnections: z.array(z.string()).default([]),
    sentry: z
        .object({
            environment: z.string(),
            release: z.string(),
            browserTracing: z.boolean().default(false),
            tracesSampleRate: z.number(),
            tracePropagationTargets: z.string().array(),
            replaysSessionSampleRate: z.number(),
            replaysOnErrorSampleRate: z.number(),
        })
        .partial()
        .optional(),
    statusPageUrl: z.string().url(),
    tagManagerArgs: z
        .object({
            id: z.string(),
            gtm_auth: z.string(),
            gtm_preview: z.string(),
        })
        .optional(),
});

export const helmValues = helmValuesSchema.parse(getProjectValues());

export const auth0ClientId = z.string().parse(getSsiValue('AUTH0_CLIENT_ID'));

export const sentryDsn = z.string().optional().parse(getSsiValue('SENTRY_DSN'));

const authRedirectUrl = new URL(helmValues.auth0AuthRedirect);
authRedirectUrl.pathname = '/oauth2/sign_out';

const logoutUrl = new URL(`https://${helmValues.auth0Domain}`);
logoutUrl.pathname = '/v2/logout';
logoutUrl.searchParams.append('client_id', auth0ClientId);
logoutUrl.searchParams.append('returnTo', authRedirectUrl.toString());

export const auth0LogoutUrl = logoutUrl.toString();
