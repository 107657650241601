import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { ContentCaption } from '../ContentCaption';

import { CustomListItemIcon } from './TextListWithIcons.styles';

type TextListItem = {
    icon: ReactNode;
    content: ReactNode;
};

export type TextListWithIconsProps = {
    title?: ReactNode;
    items: TextListItem[];
};

export const TextListWithIcons = ({ title, items }: TextListWithIconsProps) => (
    <Box>
        {title && <ContentCaption>{title}</ContentCaption>}
        <Grid container spacing={2} mt={2}>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index}>
                    <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                    <ListItemText primary={item.content} />
                </ListItem>
            ))}
        </Grid>
    </Box>
);
