import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import { CookieBotCookieDeclaration } from '@truvity/js-core__uikit';

import { AppHeader } from '../App/components/specific/AppHeader';

export const COOKIE_DECLARATION_PAGE_TITLE = 'Cookie Declaration';

export const CookieDeclarationPage = () => (
    <>
        <Helmet>
            <title>{COOKIE_DECLARATION_PAGE_TITLE}</title>
        </Helmet>
        <AppHeader />
        <Box padding={{ xs: 2, sm: 3 }}>
            <CookieBotCookieDeclaration />
        </Box>
    </>
);
