import React, { useEffect } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { MainSignupForm } from '../specific/MainSignupForm';

export const HomePage = () => {
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        sendDataToGTM({ event: 'previousUserUrl', value: document.referrer });
    }, [sendDataToGTM]);

    return <MainSignupForm />;
};
