import React from 'react';

import { AppRuntime } from './components/AppRuntime';
import { AppLayout } from './components/basic/AppLayout';
import { AppFooter } from './components/specific/AppFooter';
import { AppRoutes } from './components/specific/AppRoutes';

export const App = () => (
    <AppRuntime>
        <AppLayout content={<AppRoutes />} footer={<AppFooter />} />
    </AppRuntime>
);
