import { route } from 'react-router-typesafe-routes/dom';

export const routes = route(
    '',
    {},
    {
        HOME: route('', {}),
        COOKIE_DECLARATION: route('cookie-declaration'),
        VERIFY_EMAIL: route('verify-email'),
        DIF_HACKATHON_2024: route('dif-hackathon-2024'),
    },
);
